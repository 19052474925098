import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_input, {
      value: _ctx.plateNo,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.plateNo) = $event)),
      placeholder: "请输入车牌号",
      style: {"width":"200px","margin-right":"10px"}
    }, null, 8, ["value"]),
    _createVNode(_component_a_button, {
      type: "primary",
      onClick: _ctx.fetchParkingFee,
      loading: _ctx.isLoading
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("查询")
      ])),
      _: 1
    }, 8, ["onClick", "loading"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.isModalVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalVisible) = $event)),
      title: "停车费用",
      footer: null,
      afterClose: _ctx.afterClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, "当前费用: " + _toDisplayString(_ctx.fee) + " 元", 1),
        (_ctx.fee > 0)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.payFee
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("去付费")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.paymentMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.paymentMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "afterClose"])
  ]))
}