
import { defineComponent, ref } from 'vue';
import axios from 'axios';

export default defineComponent({
	name: 'App',
	setup() {
		const plateNo = ref('');
		const fee = ref<number | 0>(0);
		const isModalVisible = ref(false);
		const paymentMessage = ref('');
		const isLoading = ref(false);

		const fetchParkingFee = async () => {
			console.log('plateNo:', plateNo.value);
			isLoading.value = true;
			try {
				const response = await axios.get(`http://hl.goto8.us.kg/get-parking-fee?PlateNo=${plateNo.value}`);
				// fee.value = response.data.parkingFee/100;
				fee.value = 100/100;
				isModalVisible.value = true;
			} catch (error) {
				console.error('Error fetching parking fee:', error);
			} finally {
				isLoading.value = false;
			}
		}

		const afterClose = () => {
			// isModalVisible.value = false;
			console.log('Modal closed');
			paymentMessage.value = '';
		};

		const payFee = async () => {
			try {
				const response = await axios.post('http://hl.goto8.us.kg/cost', {
					plate_no: plateNo.value,
					amount: fee.value * 100 // assuming fee is in yuan and needs to be converted to cents
				});

				console.log('response:', response);

				if (response.status === 200) {
					paymentMessage.value = `支付成功: ${response.data.message}`;
				} else {
					paymentMessage.value = `支付失败: ${response.data.message}`;
				}
				console.log('支付费用成功:', fee.value);
			} catch (error: any) {
				if (error.response) {
					paymentMessage.value = `支付失败: ${error.response.data}`;
					console.error('Error paying fee:', error.response);
				} else {
					paymentMessage.value = `支付失败: ${error.message}`;
					console.error('Error paying fee:', error);
				}
			} finally {
				// isModalVisible.value = false;
			}
		};

		return {
			plateNo,
			fee,
			isModalVisible,
			paymentMessage,
			isLoading,
			fetchParkingFee,
			afterClose,
			payFee
		};
	}
});
